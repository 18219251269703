import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81')
];

export const server_loads = [43,2,5,7,9,11,35,36,13,15,45,16,18,41,20,21,33,23,25,27,30,3];

export const dictionary = {
		"/(personal-site)": [~79,[43],[44]],
		"/(app)/analytics": [48,[2,5],[,6]],
		"/(app)/dashboard": [~49,[2,7],[,8]],
		"/(app)/inbox": [50,[2]],
		"/(app)/listings/create": [~51,[2,9],[,10]],
		"/(app)/listings/edit/[listing_id]": [~52,[2,11],[,12]],
		"/(auth)/login": [~74,[35,36],[,37]],
		"/(auth)/login/change-password": [~75,[35,36],[,37]],
		"/(auth)/login/forgot-password": [~76,[35,36,38],[,37]],
		"/(app)/preview/profile": [~53,[2,13],[,14]],
		"/(app)/profile-sharing": [54,[2,15]],
		"/(app)/property-matching": [56,[2]],
		"/(app)/property-matching/posts": [~57,[2]],
		"/(personal-site)/property-view/[listing_id]": [~80,[43,45],[44,46]],
		"/(app)/property/[listing_id]": [~55,[2,16],[,17]],
		"/sentry-example": [81],
		"/(app)/settings/[user_id]": [~58,[2,18],[,19]],
		"/(app)/settings/[user_id]/license": [~59,[2,18],[,19]],
		"/(app)/settings/[user_id]/profile": [~60,[2,18],[,19]],
		"/(app)/settings/[user_id]/social-profiles": [~61,[2,18],[,19]],
		"/(app)/settings/[user_id]/subscriptions": [~62,[2,18],[,19]],
		"/(auth)/signup": [~77,[35,39],[,40]],
		"/(auth)/signup/setup-profile": [~78,[35,39,41],[,40,42]],
		"/(app)/subscribe": [~63,[2,20]],
		"/(app)/success": [~64,[2]],
		"/(app)/teams": [~65,[2,21],[,22]],
		"/(app)/teams/accept-invite/[invite_id]": [~71,[2,21,32],[,22]],
		"/(app)/teams/create": [~72,[2,21,33],[,22,34]],
		"/(app)/teams/find-teams": [73,[2,21],[,22]],
		"/(app)/teams/[team_id]": [66,[2,21,23],[,22,24]],
		"/(app)/teams/[team_id]/create-listing": [~67,[2,21,23,25],[,22,24,26]],
		"/(app)/teams/[team_id]/edit-listing/[listing_id]": [~68,[2,21,23,27],[,22,24,28]],
		"/(app)/teams/[team_id]/settings": [~69,[2,21,23,29],[,22,24]],
		"/(app)/teams/[team_id]/settings/members": [~70,[2,21,23,29,30],[,22,24,,31]],
		"/(app)/[broker_id]": [~47,[2,3],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';